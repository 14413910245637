const itemsVR = [
  [
    {
      title: "Star Catcher",
    },
    {
      text: "Decide",
      checked: true,
    },
    {
      text: "Invisible",
      checked: false,
    },
  ],
  [
    {
      title: "Sun Blazer",
    },
    {
      text: "Divert",
      checked: true,
    },
  ],
  [
    {
      title: "Mind Bender",
    },
  ],
];

const configMenu = [
  {
    id: "vr",
    text: "VR",
  },
];

export { configMenu, itemsVR };
