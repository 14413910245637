<template>
  <div class="container-selectbox w-5/12 lg:w-2/6 2xl:w-1/5">
    <div class="select-box">
      <div class="options-container" ref="optionsContainer">
        <div
          class="option"
          @click="selectOption(option)"
          ref="option"
          v-for="option in options"
          :key="option.id"
        >
          <input
            type="radio"
            class="radio"
            :id="option.id"
            name="category"
            :value="option.id"
          />
          <label for="users" class="flex items-center gap-3"
            ><img src="@/assets/icons/profile-icon.svg" alt="Icon user" />{{
              option.username
            }}</label
          >
        </div>
      </div>

      <div class="selected flex items center gap-3" @click="handleSelected">
        <img src="@/assets/icons/profile-icon.svg" alt="Icon user" />
        <span>{{ userSelected }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineEmits, defineProps } from "vue";
const { options } = defineProps(["options"]);
const emit = defineEmits(["changeUser"]);
const optionsContainer = ref(null);
const userSelected = ref(options[0]?.username);

function handleSelected() {
  optionsContainer.value.classList.toggle("active");
}

function selectOption(user) {
  userSelected.value = user.username;
  optionsContainer.value.classList.remove("active");
  emit("changeUser", user);
}
</script>
<style lang="scss" scoped>
.container-selectbox {
  position: relative;
  left: 0;
  top: 0;
}

.select-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
}

.select-box .options-container {
  background: #ecf0f5;
  color: #81848d;
  max-height: 0;
  width: 100%;
  opacity: 0;
  overflow: hidden;

  order: 1;
}

.selected {
  background: #ecf0f5;
  margin-bottom: 8px;
  color: #81848d;
  position: relative;
  width: 100%;
  order: 0;
}

.selected::after {
  content: "";
  position: absolute;
  right: 21px;
  top: 50%;
  height: 25px;
  width: 25px;
  background: url("~@/assets/icons/arrow-down-icon.png");
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.4s;
  transform: translateY(-50%);
}

.select-box .options-container.active {
  max-height: 240px;
  opacity: 1;
  overflow-y: auto;
  z-index: 5;
  position: absolute;
  top: 50px;
}

.select-box .options-container.active + .selected::after {
  transform: translateY(-50%) rotateX(180deg);
}

.select-box .options-container::-webkit-scrollbar {
  width: 4px;
  background: #1d2e48;
  border-radius: 1px;
}

.select-box .options-container::-webkit-scrollbar-thumb {
  background: #d5dbe2;
  border-radius: 1px;
  height: 20px;
}

.select-box .option,
.selected {
  padding: 12px 24px;
  cursor: pointer;
  border: 1px solid white;
}

.select-box .option:hover {
  background: #d5dbe2;
}

.select-box label {
  cursor: pointer;
}

.select-box .option .radio {
  display: none;
}
</style>
