<template>
  <section class="w-full max-h-screen">
    <div class="px-7 h-screen pt-7">
      <div class="w-100 flex flex-col justify-center">
        <h1 class="text-white text-lg font-semibold">Traning</h1>
        <traning-steps-without-connection
          :steps="steps"
        ></traning-steps-without-connection>
        <traning-steps :steps="steps"></traning-steps>
        <section class="mt-4">
          <traning-select-user
            :users="users"
            @changeUser="filterTraningByUser"
          />
          <traning-menu @seeView="changeView" />
          <section
            class="w-full flex flex-col justify-between bg-white px-11 py-12 gap-24 border border-solid border-gray-200"
          >
            <traning-tablet v-if="seeTablet" :percents="['33', '23']" />
            <traning-v-r v-else :percents="['78', '46']" />
          </section>
        </section>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";
import TraningSteps from "@/components/traning/TraningSteps.vue";
import TraningStepsWithoutConnection from "@/components/traning/TraningStepsWithoutConnection.vue";
import TraningMenu from "@/components/traning/TraningMenu.vue";
import TraningVR from "@/components/traning/TraningVR.vue";
import TraningSelectUser from "@/components/traning/TraningSelectUser.vue";
const seeTablet = ref(true);
function changeView() {
  seeTablet.value = !seeTablet.value;
}

const steps = [
  {
    number: 6,
    active: true,
    date: "In Progress",
  },
  {
    number: 7,
    active: false,
    date: "30/11/2022",
  },
  {
    number: 8,
    active: false,
    date: "01/12/2022",
  },
  {
    number: 9,
    active: false,
    date: "02/12/2022",
  },
];

const users = [
  {
    id: 100,
    text: "Sara Patricia Franco",
  },
  {
    id: 101,
    text: "Laura Cardona",
  },
  {
    id: 102,
    text: "Sofia Acevedo",
  },
  {
    id: 103,
    text: "Christian Hernandez",
  },
  {
    id: 104,
    text: "Alexsander Nanclares",
  },
];

function filterTraningByUser(idUser) {
  alert(`Filter information by user: ${idUser}`);
}
</script>

<style>
.submit-btn {
  background-color: #edfe86;
  border: none;
}
</style>
