<template>
  <div class="bg-white w-1/5">
    <div class="grid grid-cols-2 text-center font-bold">
      <template v-for="menu in configMenu" :key="menu.id">
        <input class="hidden checked-card" type="radio" name="menu" :id="menu.id" :checked="menu.id === 'tablet'" @change="changeViewMenu(menu.id)"/>
        <label :for="menu.id" class="text-white bg-gray-800 p-2 cursor-pointer">{{ menu.text }}</label>
      </template>
    </div>
  </div>
</template>

<script setup>
import { configMenu } from "@/components/traning/utils"
const emit = defineEmits()

function changeViewMenu(idView) {
  emit('seeView', idView === 'tablet');
}
</script>

<style lang="scss" scoped>
.checked-card:checked+label {
  background-color: white;
  color: #1D2E48;
}
</style>
