<template>
  <div v-if="title">
    <p class="font-bold">{{ title }}</p>
  </div>
  <div v-else class="flex items-center w-full">
    <div>
      <img :src="image" alt="Icon item"/>
    </div>
    <p class="ml-5">{{ text || 'Right Head Turns'}}</p>
  </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps(['checked','title','text']);
const image =  computed(() => require(`@/assets/icons/${props?.checked ? 'checked-traning-icon.svg': 'not-checked-traning-icon.svg'}`));
</script>

<style lang="scss" scoped>
</style>
