<template>
    <div class="container">
        <ul class="progressbar">
            <div>
                <li v-for="step in steps" :key="step.number" :class="{ 'active': step.active }"
                    :style="style(step.number)" class="text-white">{{
        step.date
                    }}</li>
            </div>
        </ul>
    </div>
</template>
<script setup>
defineProps(['steps'])
function style(number) {
    return { "--content": `'${number}'` }
}
</script>
  
<style lang="css" scoped>
.container {
    width: 60%;
    position: relative;
    margin-top: 26px;
    left: 8%;
    top: 27%;
}

.progressbar {
    counter-reset: step;
}

.progressbar div li {
    content: 'eqeq';
    display: block;
    float: left;
    width: 19.28%;
    position: relative;
    text-align: center;
}

.progressbar div li:before {
    content: var(--content, 'Good');
    counter-increment: step;
    width: 35px;
    height: 35px;
    display: block;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    line-height: 35px;
    color: #1D2E48;
    text-align: center;
    font-weight: bold;
    background-color: #C2C8CE;
}

.progressbar div li:after {
    position: absolute;
    width: 100%;
    height: 3px;
    background: #979797;
    top: 15px;
    left: -50%;
}

.progressbar div li.active:before {
    background: #EDFE86;
    color: #1D2E48;
}


.progressbar div li.active+li:before {
    color: #1D2E48;
    width: 35px;
    height: 35px;
}

.progressbar div li:first-child:after {
    content: none;
}
</style>